import { lazy, Suspense } from "react";
import {
  createTheme,
  CssBaseline,
  ThemeProvider,
  GlobalStyles,
} from "@mui/material";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { AuthProvider } from "./contexts/auth";
import { ConfirmProvider } from "material-ui-confirm";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js";
import localizedFormat from "dayjs/plugin/localizedFormat.js";
import "./App.scss";

dayjs.extend(utc);
dayjs.extend(localizedFormat);

export const SuspenseLoading = (
  <div className="py-3 text-center">Loading...</div>
);

const ProtectedRoute = lazy(() => import("./ProtectedRoute"));
const PublicRoute = lazy(() => import("./PublicRoute"));
const Home = lazy(() => import("./views/Pages/Home"));
const Login = lazy(() => import("./views/Pages/Login"));
const Page404 = lazy(() => import("./views/Pages/Page404"));
const Page500 = lazy(() => import("./views/Pages/Page500"));
const SignUp = lazy(() => import("./pages/SignUp.js"));
const Subscriptions = lazy(() => import("./pages/subscriptions"));
const ResetPassword = lazy(() =>
  import("./views/Pages/Login/ResetPassword.js")
);
const CountiesMap = lazy(() => import("./pages/CountiesMap.js"));

export const drawerWidth = 250;

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", '"Helvetica Neue"', "Arial", "sans-serif"].join(
      ","
    ),
  },
  palette: {
    secondary: {
      main: "#C6A780",
      contrastText: "#fff",
    },
    primary: {
      main: "#3E5B77",
    },
    background: {
      default: "#c8c8c8",
    },
    default: {
      main: "#e0e0e0",
      contrastText: "#3E5B77",
    },
    muted: {
      main: "#73818f",
    },
    gold: {
      main: "#c69f6f",
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        root: ({ ownerState: { open }, theme }) => ({
          width: drawerWidth,
          flexShrink: 0,
          ...(!open && {
            overflowX: "hidden",
            width: theme.spacing(6),
            [theme.breakpoints.up("sm")]: {
              width: theme.spacing(7),
            },
          }),
        }),
        paper: ({ ownerState: { open }, theme, ...props }) => ({
          width: drawerWidth,
          color: "#fff",
          backgroundColor: "#2f353a",
          ...(open && {
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }),
          ...(!open && {
            width: theme.spacing(6),
            [theme.breakpoints.up("sm")]: {
              width: theme.spacing(7),
            },
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          }),
        }),
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: "#fff",
          zIndex: theme.zIndex.drawer + 1,
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }),
      },
    },
  },
});

const globalStyles = (
  <GlobalStyles
    styles={{
      a: {
        color: "#20a8d8",
        textDecoration: "none",
      },
    }}
  />
);

export default function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={SuspenseLoading}>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          {globalStyles}

          <ConfirmProvider>
            <AuthProvider>
              <Routes>
                <Route
                  exact
                  path="/"
                  element={
                    <Suspense fallback={SuspenseLoading}>
                      <PublicRoute>
                        <Login />
                      </PublicRoute>
                    </Suspense>
                  }
                />

                <Route
                  path="/sign-up"
                  element={
                    <Suspense fallback={SuspenseLoading}>
                      <PublicRoute>
                        <SignUp />
                      </PublicRoute>
                    </Suspense>
                  }
                />

                <Route
                  path="/subscribe"
                  element={<Navigate replace to="/sign-up" />}
                />

                <Route
                  path="/resetpassword/:id"
                  element={
                    <Suspense fallback={SuspenseLoading}>
                      <ResetPassword />
                    </Suspense>
                  }
                />

                <Route
                  path="/home/*"
                  element={
                    <Suspense fallback={SuspenseLoading}>
                      <ProtectedRoute>
                        <Home />
                      </ProtectedRoute>
                    </Suspense>
                  }
                />

                <Route
                  path="/subscriptions/*"
                  element={
                    <Suspense fallback={SuspenseLoading}>
                      <ProtectedRoute>
                        <Subscriptions />
                      </ProtectedRoute>
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/500"
                  name="Page 500"
                  element={
                    <Suspense fallback={SuspenseLoading}>
                      <Page500 />
                    </Suspense>
                  }
                />

                <Route
                  exact
                  path="/map"
                  name="Counties Map"
                  element={
                    <Suspense fallback={SuspenseLoading}>
                      <CountiesMap />
                    </Suspense>
                  }
                />

                <Route
                  path="*"
                  element={
                    <Suspense fallback={SuspenseLoading}>
                      <Page404 />
                    </Suspense>
                  }
                />
              </Routes>
            </AuthProvider>
          </ConfirmProvider>
        </ThemeProvider>
      </Suspense>
    </BrowserRouter>
  );
}
