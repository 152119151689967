import axios from "axios";
import { captureException } from "@sentry/react";
import { useEffect, useState } from "react";

axios.defaults.withCredentials = true;

export function checkTwilioIntegration() {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/twilio/checkTwilioIntegration`
  );
}

export function getMessagesStatistic(params) {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/twilio/messageStatistics`,
    { params }
  );
}

export async function getCurrentUser() {
  try {
    const response = await axios({
      url: process.env.REACT_APP_API_URL + "/users/me",
    });

    return response.data;
  } catch (err) {
    // skip 403 errors
    if (!err.response || err.response.status !== 403) {
      captureException(err);
      console.error(err);
    }
  }
}

export function userLogout() {
  return axios({
    method: "DELETE",
    url: process.env.REACT_APP_API_URL + "/users/logout",
  });
}

export function signUp(data) {
  return axios({
    method: "POST",
    url: process.env.REACT_APP_API_URL + "/users/create",
    data: data,
  });
}

export function getMailchimpTemplates() {
  return axios.get(`${process.env.REACT_APP_API_URL}/mailchimp/templates`);
}

export function connectToTwilio(data) {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/twilio/createTwilioService`,
    { ...data }
  );
}

export function updateTwilio(data) {
  return axios.put(
    `${process.env.REACT_APP_API_URL}/twilio/editTwilioParameters`,
    { ...data }
  );
}

export async function getCounties(fullList) {
  try {
    const query = fullList ? "?fullList=true" : "";
    const counties = await axios.get(
      `${process.env.REACT_APP_API_URL}/counties${query}`
    );

    return counties.data;
  } catch (e) {
    captureException(e);

    return { counties: [] };
  }
}

export function useCounties() {
  const [loading, setLoading] = useState(true);
  const [counties, setCounties] = useState([]);

  useEffect(() => {
    getCounties()
      .then((data) => setCounties(data.counties))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { loading, counties };
}

export function getLeads(data) {
  return axios({
    url: process.env.REACT_APP_API_URL + "/leads",
    params: data,
  });
}

export function addMailchimpIdFun(data) {
  return axios({
    method: "POST",
    url: process.env.REACT_APP_API_URL + "/mailchimp/addAudience",
    data: data,
  });
}

export function resetPassword(data) {
  return axios({
    method: "POST",
    url: process.env.REACT_APP_API_URL + "/users/forgetPassword",
    data: data,
  });
}

export function updatePassword(data) {
  return axios({
    method: "POST",
    url: process.env.REACT_APP_API_URL + "/users/submitPassword",
    data: data,
  });
}

export function useSubscriptionPlans() {
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    axios(process.env.REACT_APP_API_URL + "/subscription/plans")
      .then((response) => {
        setPlans(response.data.data.data);
      })
      .catch((err) => {
        captureException(err);
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return {
    loading,
    plans,
  };
}

export function userLogin(data) {
  return axios({
    method: "POST",
    url: process.env.REACT_APP_API_URL + "/users/login",
    data: data,
  });
}

export function userUpdate(data) {
  return axios({
    method: "POST",
    url: process.env.REACT_APP_API_URL + "/users/update",
    data: data,
  });
}

export function getAllUserSubscriptions() {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/subscription/getUserSubscriptions`
  );
}

export function cancelSubscription(data) {
  return axios({
    method: "POST",
    url: process.env.REACT_APP_API_URL + "/subscription/cancel",
    data,
  });
}

export function createMailchimpTemplate(data) {
  return axios({
    method: "POST",
    url: process.env.REACT_APP_API_URL + "/mailchimp/addTemplatenew",
    data: data,
  });
}

export function createStripeCheckoutSession(data) {
  return axios({
    method: "POST",
    url: process.env.REACT_APP_API_URL + "/subscription/checkout",
    data,
  });
}

export function updateSubscription(data) {
  return axios({
    method: "POST",
    url: process.env.REACT_APP_API_URL + "/subscription/update",
    data,
  });
}
